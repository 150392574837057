import { CommonStringKeys } from './common-strings'
import { parseArray, parseNonEmptyString } from './parsing'

export class BackendError extends Error {
  // see LocalizedStringsMethods.formatString
  constructor(public readonly errorKey: CommonStringKeys, public readonly params: string[] = []) {
    super(errorKey)
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, BackendError.prototype)
  }

  public static parse(errorMessage: string): BackendError | undefined {
    try {
      const errorJson = JSON.parse(errorMessage)
      const errorKey = parseNonEmptyString(errorJson, 'errorKey')
      const params = parseArray(errorJson, 'params').map((param) => param as string)
      return new BackendError(errorKey as CommonStringKeys, params)
    } catch (e) {
      return undefined
    }
  }
}

export class UnauthorizedError extends Error {
  constructor() {
    super()
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, UnauthorizedError.prototype)
  }
}
