/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// why are properties readonly here and how does it all come together?
// https://www.typescriptlang.org/docs/handbook/2/objects.html#readonly-properties
import { GeoPoint, ID, ImageWithSize, parseGeoPoint, parsePoint, Point } from './model'
import { parseNumber, parseString } from './parsing'
import { DelaunayModel } from './interactive-map/delaunay-model'

export interface InteractiveMapModel {
  apply(geoPoint: GeoPoint): Point
}

export interface PointMapping {
  readonly geoPoint: GeoPoint
  readonly imagePoint: Point
}

export function parsePointMapping(json: any): PointMapping {
  return {
    geoPoint: parseGeoPoint(json, 'geoPoint'),
    imagePoint: parsePoint(json, 'imagePoint'),
  }
}

export interface UserPoint extends PointMapping {
  readonly authorId: ID
}

export interface InteractiveMapDraftPoint extends UserPoint {
  readonly id: ID
  readonly mapId: ID
}

export interface InteractiveMapContent {
  readonly name: string
  readonly image: ImageWithSize
}

export interface InteractiveMap extends InteractiveMapContent {
  readonly id: ID
  readonly activeSnapshotId?: ID
  readonly mapThumbnailUrl: string
}

export interface InteractiveMapModelSnapshot extends InteractiveMapContent {
  readonly id: ID
  readonly model: DelaunayModel
  readonly mapId: ID
  readonly viewerCanEdit: boolean
}

export interface InteractiveMapModelSnapshotPoints extends InteractiveMapModelSnapshot {
  readonly points: UserPoint[]
}

export interface InteractiveMapView {
  readonly id: ID
  readonly name: string
  readonly centerPosition: GeoPoint
  readonly thumbnailUrl: string
  readonly snapshotId: ID
}

export function parseInteractiveMapView(row: any): InteractiveMapView {
  return {
    id: parseNumber(row, 'id'),
    name: parseString(row, 'name'),
    centerPosition: parseGeoPoint(row, 'centerPosition'),
    thumbnailUrl: parseString(row, 'thumbnailUrl'),
    snapshotId: parseNumber(row, 'snapshotId'),
  }
}
