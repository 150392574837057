import { PinzmeClient } from '@pinzme/common/src/pinzme-client'
import { TokenProvider } from '@pinzme/common/src/token-provider'
import { AppInfo } from '@pinzme/common/src/app-info'

export class EmptyTokenProvider implements TokenProvider {
  provideToken(): Promise<string | undefined> {
    return Promise.resolve(undefined)
  }

  removeToken(): Promise<void> {
    return Promise.resolve(undefined)
  }

  setToken(_: string): Promise<void> {
    return Promise.resolve(undefined)
  }
}

const emptyAppInfo: AppInfo = {
  applicationName: 'pizza',
  brand: '',
  buildNumber: '',
  bundleId: '',
  deviceId: '',
  deviceType: '',
  hasNotch: false,
  isLandscape: false,
  isTablet: false,
  systemName: '',
  systemVersion: '',
  uniqueId: '',
  version: '',
}

export const pinzmeWeb = new PinzmeClient('https://api.pinz.me', new EmptyTokenProvider(), emptyAppInfo)
