// eslint-disable-next-line no-shadow
export enum CommonStringKeys {
  tokenExpired = 'tokenExpired',
  youAreNotAuthorized = 'youAreNotAuthorized',
  youAreBanned = 'youAreBanned',
  youShouldBePinzmeAdmin = 'youShouldBePinzmeAdmin',
  notEnoughRightsToEditMarker = 'notEnoughRightsToEditMarker',
  onlyAuthorCanEditComment = 'onlyAuthorCanEditComment',
  youShouldBeChannelAdmin = 'youShouldBeChannelAdmin',
  youShouldBeAuthorizedToChannelAccess = 'youShouldBeAuthorizedToChannelAccess',
  youShouldBeChannelParticipant = 'youShouldBeChannelParticipant',
  notAbleToCloneInSameChannel = 'notAbleToCloneInSameChannel',
  incorrectLoginOrPassword = 'incorrectLoginOrPassword',
  incorrectLogin = 'incorrectLogin',
  incorrectPassword = 'incorrectPassword',
  youAreRobot = 'youAreRobot',
  moreComplexPassword = 'moreComplexPassword',
  badToken = 'badToken',
  newPasswordShouldBeDifferent = 'newPasswordShouldBeDifferent',
  currentInvalidPassword = 'currentInvalidPassword',
  notEmptyName = 'notEmptyName',
  unavailableLogin = 'unavailableLogin',
  unavailableEmail = 'unavailableEmail',
  unavailableChannelLogin = 'unavailableChannelLogin',

  noRequiredField = 'noRequiredField',
  expectedNotEmptyString = 'expectedNotEmptyString',
  shouldBeArrayField = 'shouldBeArrayField',
  shouldBeDictionaryField = 'shouldBeDictionaryField',
  invalidBooleanJsonValue = 'invalidBooleanJsonValue',
  invalidStringJsonValue = 'invalidStringJsonValue',
  invalidNumberJsonValue = 'invalidNumberJsonValue',

  unableToSendEmail = 'unableToSendEmail',
}

export const CommonStrings = {
  en: {
    [CommonStringKeys.tokenExpired]: 'Your token is rotten. Please login again',
    [CommonStringKeys.youAreNotAuthorized]: "You're not authorized",
    [CommonStringKeys.youAreBanned]: 'Your account has been banned. To unblock it, contact support@pinz.me"',
    [CommonStringKeys.youShouldBePinzmeAdmin]: 'You must be a PinzMe administrator',
    [CommonStringKeys.notEnoughRightsToEditMarker]: 'Insufficient rights to edit marker',
    [CommonStringKeys.onlyAuthorCanEditComment]:
      "Only the author of the comment or marker's author can edit the comment",
    [CommonStringKeys.youShouldBeChannelAdmin]: 'You must be a channel admin',
    [CommonStringKeys.youShouldBeAuthorizedToChannelAccess]: 'You must be signed in to open the channel',
    [CommonStringKeys.youShouldBeChannelParticipant]: 'You must be a member of this channel to open it',
    [CommonStringKeys.notAbleToCloneInSameChannel]: 'Cannot be cloned into the same channel',
    [CommonStringKeys.incorrectLoginOrPassword]: 'Incorrect login or password',
    [CommonStringKeys.incorrectLogin]: 'Incorrect login',
    [CommonStringKeys.incorrectPassword]: 'Incorrect password',
    [CommonStringKeys.youAreRobot]: 'You have not passed the Robot Test',
    [CommonStringKeys.moreComplexPassword]: 'Come up with a more complex password',
    [CommonStringKeys.badToken]: 'Unable to verify user',
    [CommonStringKeys.newPasswordShouldBeDifferent]: 'The new password must be different from the old password',
    [CommonStringKeys.currentInvalidPassword]: 'The current password was entered incorrectly',
    [CommonStringKeys.notEmptyName]: 'Username must contain text',
    [CommonStringKeys.unavailableLogin]: 'Invalid login',
    [CommonStringKeys.unavailableEmail]: 'Invalid address',
    [CommonStringKeys.unavailableChannelLogin]: 'Invalid channel login',
    [CommonStringKeys.noRequiredField]: 'Required field "{0}" is missing',
    [CommonStringKeys.expectedNotEmptyString]: 'The value of the field "{0}" cannot be an empty string',
    [CommonStringKeys.shouldBeArrayField]: 'The value of the field "{0}" must be an array',
    [CommonStringKeys.shouldBeDictionaryField]: 'The value of the field "{0}" must be a dictionary',
    [CommonStringKeys.invalidBooleanJsonValue]: 'Expected true / false for key "{0}", but found {1}',
    [CommonStringKeys.invalidStringJsonValue]: 'Expected string by key "{0}", but found {1}',
    [CommonStringKeys.invalidNumberJsonValue]: 'Expected number by key "{0}", but found {1}',
    [CommonStringKeys.unableToSendEmail]: 'Unable to send email',
  },
  ru: {
    [CommonStringKeys.tokenExpired]: 'Ваш токен протух. Залогиньтесь заново',
    [CommonStringKeys.youAreNotAuthorized]: 'Вы не авторизованы',
    [CommonStringKeys.youAreBanned]:
      'Ваш аккаунт забанен. Для его разблокировки обратитесь в поддержку support@pinz.me',
    [CommonStringKeys.youShouldBePinzmeAdmin]: 'Вы должны быть администратором PinzMe',
    [CommonStringKeys.notEnoughRightsToEditMarker]: 'Недостаточно прав для редактирования точки',
    [CommonStringKeys.onlyAuthorCanEditComment]: 'Только автор комментария или точки может редактировать комментарий',
    [CommonStringKeys.youShouldBeChannelAdmin]: 'Вы должны быть администратором канала',
    [CommonStringKeys.youShouldBeAuthorizedToChannelAccess]: 'Вы должны быть авторизованы для просмотра канала',
    [CommonStringKeys.youShouldBeChannelParticipant]: 'Вы должны быть участником этого канала для его просмотра',
    [CommonStringKeys.notAbleToCloneInSameChannel]: 'Нельзя клонировать в тот же канал',
    [CommonStringKeys.incorrectLoginOrPassword]: 'Неверный логин или пароль',
    [CommonStringKeys.incorrectLogin]: 'Недопустимый логин',
    [CommonStringKeys.incorrectPassword]: 'Недопустимый пароль',
    [CommonStringKeys.youAreRobot]: 'Вы не прошли проверку на робота',
    [CommonStringKeys.moreComplexPassword]: 'Придумайте более сложный пароль',
    [CommonStringKeys.badToken]: 'Невозможно верифицировать пользователя',
    [CommonStringKeys.newPasswordShouldBeDifferent]: 'Новый пароль должен отличаться от старого пароля',
    [CommonStringKeys.currentInvalidPassword]: 'Текущий пароль введен неверно',
    [CommonStringKeys.notEmptyName]: 'Имя пользователя должно содержать текст',
    [CommonStringKeys.unavailableLogin]: 'Недопустимый логин',
    [CommonStringKeys.unavailableEmail]: 'Недопустимый адрес',
    [CommonStringKeys.unavailableChannelLogin]: 'Недопустимый логин канала',
    [CommonStringKeys.noRequiredField]: 'Нет необходимого поля "{0}"',
    [CommonStringKeys.expectedNotEmptyString]: 'Значение поля "{0}" не может быть пустой строкой',
    [CommonStringKeys.shouldBeArrayField]: 'Значение поля "{0}" должно быть массивом',
    [CommonStringKeys.shouldBeDictionaryField]: 'Значение поля "{0}" должно быть словарем',
    [CommonStringKeys.invalidBooleanJsonValue]: 'Ожидается true/false по ключу "{0}", но найден {1}',
    [CommonStringKeys.invalidStringJsonValue]: 'Ожидается строка по ключу "{0}", но найден {1}',
    [CommonStringKeys.invalidNumberJsonValue]: 'Ожидается число по ключу "{0}", но найден {1}',
    [CommonStringKeys.unableToSendEmail]: 'Не удалось отправить письмо',
  },
}
