export function sleep(ms: number): Promise<any> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function average(arr: number[]): number {
  return arr.reduce((p, c) => p + c, 0) / arr.length
}

// https://stackoverflow.com/a/63116134
export function kebabize(str: string): string {
  return str
    .split('')
    .map((letter, idx) => {
      return letter.toUpperCase() === letter ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}` : letter
    })
    .join('')
}

export function generateRandomString(length = 24): string {
  // https://stackoverflow.com/a/1349426
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export function tomorrow(): Date {
  return new Date(Date.now() + 24 * 3600 * 1000)
}
