/* eslint-disable @typescript-eslint/no-unsafe-return,@typescript-eslint/explicit-module-boundary-types */

import { BackendError } from './error'
import { CommonStringKeys } from './common-strings'

export function hasField(json: any, key: string): boolean {
  const value = json[key]
  return value !== undefined && value !== null
}

export function requireField(json: any, key: string): any {
  if (hasField(json, key)) {
    return json[key]
  }
  throw new BackendError(CommonStringKeys.noRequiredField, [key])
}

export function parseNumber(json: any, key: string): number {
  const value = requireField(json, key)
  if (typeof value === 'number') {
    return value
  }
  if (typeof value === 'string') {
    return +value
  }
  throw new BackendError(CommonStringKeys.invalidNumberJsonValue, [key, typeof value])
}

export function parseNumberIfPresent(json: any, key: string): number | undefined {
  return hasField(json, key) ? parseNumber(json, key) : undefined
}

export function parseString(json: any, key: string): string {
  const value = requireField(json, key)
  if (typeof value === 'string') {
    return value
  }
  throw new BackendError(CommonStringKeys.invalidStringJsonValue, [key, typeof value])
}

export function parseStringIfPresent(json: any, key: string): string | undefined {
  return hasField(json, key) ? parseString(json, key) : undefined
}

export function parseStringIfNonEmpty(json: any, key: string): string | undefined {
  const value = parseStringIfPresent(json, key)
  return value?.trim().length ? value : undefined
}

export function parseBoolean(json: any, key: string): boolean {
  const value = requireField(json, key)
  if (typeof value === 'boolean') {
    return value
  }
  if (value === 'true') {
    return true
  }
  if (value === 'false') {
    return false
  }
  throw new BackendError(CommonStringKeys.invalidBooleanJsonValue, [key, typeof value])
}

export function parseBooleanIfPresent(json: any, key: string): boolean | undefined {
  return hasField(json, key) ? parseBoolean(json, key) : undefined
}

export function parseDate(json: any, key: string): Date {
  const value = requireField(json, key)
  if (value instanceof Date) {
    return value
  }
  return new Date(parseString(json, key))
}

export function parseDateIfPresent(json: any, key: string): Date | undefined {
  return hasField(json, key) ? parseDate(json, key) : undefined
}

export function parseNonEmptyString(json: any, key: string): string {
  const value = parseString(json, key)
  if (!value.trim().length) {
    throw new BackendError(CommonStringKeys.expectedNotEmptyString, [key])
  }
  return value
}

export function parseIntIfPresent(s?: string): number | undefined {
  return s ? parseInt(s, 10) : undefined
}

export function parseArray(json: any, key: string): any[] {
  const array = requireField(json, key)
  if (!Array.isArray(array)) {
    throw new BackendError(CommonStringKeys.shouldBeArrayField, [key])
  }
  return array
}

export function parseDictionary(json: any, key: string): { [key: string]: any } {
  const dict = requireField(json, key)
  if (dict.constructor !== Object) {
    throw new BackendError(CommonStringKeys.shouldBeDictionaryField, [key])
  }
  return dict
}
