/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  parseArray,
  parseBoolean,
  parseBooleanIfPresent,
  parseDate,
  parseDateIfPresent,
  parseDictionary,
  parseNonEmptyString,
  parseNumber,
  parseNumberIfPresent,
  parseString,
  parseStringIfPresent,
  requireField,
} from './parsing'
import { InteractiveMap, InteractiveMapDraftPoint, InteractiveMapModelSnapshot } from './map-model'
import { parseDelaunayModel } from './interactive-map/delaunay-model'

export type ID = number

export type LocalizedString = {
  [language: string]: string
}

export interface GeoPoint {
  readonly latitude: number
  readonly longitude: number
}

export function parseGeoPoint(json: any, key?: string): GeoPoint {
  if (key) {
    return parseGeoPoint(requireField(json, key))
  }
  return {
    latitude: parseNumber(json, 'latitude'),
    longitude: parseNumber(json, 'longitude'),
  }
}

export interface Point {
  readonly x: number
  readonly y: number
}

export function parsePoint(json: any, key?: string): Point {
  if (key) {
    return parsePoint(requireField(json, key))
  }
  return {
    x: parseNumber(json, 'x'),
    y: parseNumber(json, 'y'),
  }
}

export interface Size {
  readonly width: number
  readonly height: number
}

export function parseSize(json: any, key?: string): Size {
  if (key) {
    return parseSize(requireField(json, key))
  }
  return {
    width: parseNumber(json, 'width'),
    height: parseNumber(json, 'height'),
  }
}

export interface ImageWithSize {
  readonly url: string
  readonly size: Size
}

export function parseImageWithSize(json: any, key?: string): ImageWithSize {
  if (key) {
    return parseImageWithSize(requireField(json, key))
  }
  return {
    url: parseString(json, 'url'),
    size: parseSize(json, 'size'),
  }
}

export interface UserChannelInfo {
  readonly name: string
  readonly isPublic: boolean
  readonly isGroup: boolean
  readonly channelKey: string
  readonly avatarUrl?: string
  readonly commentsEnabled: boolean
}

export function parseUserChannelInfo(json: any, key?: string): UserChannelInfo {
  if (key) {
    return parseUserChannelInfo(requireField(json, key))
  }
  return {
    name: parseNonEmptyString(json, 'name'),
    isPublic: parseBoolean(json, 'isPublic'),
    isGroup: parseBoolean(json, 'isGroup'),
    channelKey: parseNonEmptyString(json, 'channelKey'),
    avatarUrl: parseStringIfPresent(json, 'avatarUrl'),
    commentsEnabled: parseBooleanIfPresent(json, 'commentsEnabled') ?? true, // TODO: remove ??
  }
}

/**
 * Отображение канала для пользователя
 */
export interface ChannelView extends UserChannelInfo {
  readonly id: ID
  /**
   * Является ли пользователь участником канала
   */
  readonly isViewerParticipant: boolean
  /**
   * Является ли пользователь, который смотрит на канал, его админом
   */
  readonly isViewerAdmin: boolean
  /**
   * Включены  у пользователя нотификации о новых событиях в канале
   */
  readonly notify: boolean
  /**
   * Количество непрочитанных у пользователя точек в канале
   */
  readonly unreadCount?: number
  /**
   * Может ли пользователь стать участником канала
   */
  readonly canViewerJoin: boolean
  /**
   * Может ли пользователь добавить маркер
   */
  readonly canViewerAddMarker: boolean
}

export function parseChannel(json: any): ChannelView {
  return {
    ...parseUserChannelInfo(json),
    id: parseNumber(json, 'id'),
    isViewerParticipant: parseBoolean(json, 'isViewerParticipant'),
    isViewerAdmin: parseBoolean(json, 'isViewerAdmin'),
    notify: parseBoolean(json, 'notify'),
    canViewerJoin: parseBoolean(json, 'canViewerJoin'),
    unreadCount: parseNumberIfPresent(json, 'unreadCount'),
    canViewerAddMarker: parseBoolean(json, 'canViewerAddMarker'),
  }
}

export interface ChannelParticipant {
  readonly user: User
  readonly isAdmin: boolean
  readonly joinedAt: Date
}

export function parseChannelParticipant(json: any): ChannelParticipant {
  return {
    user: parseUser(json.user),
    isAdmin: parseBoolean(json, 'isAdmin'),
    joinedAt: parseDate(json, 'joinedAt'),
  }
}

export interface MarkerMapView {
  readonly id?: ID
  // ключ идемпотентности на клиенте, чтобы различать добавляемые точки
  readonly key?: string
  readonly geoPoint: GeoPoint
  readonly title: string
  readonly mapImageUrl?: string
  readonly canViewerEdit: boolean
  readonly address: string
  readonly deadline?: Date
  readonly read: boolean
}

export interface MarkerView extends MarkerMapView {
  readonly id: ID
  readonly mapImageUrl: string
  readonly isApproved?: boolean
}

export function parseMarkerView(json: any): MarkerView {
  return {
    id: parseNumber(json, 'id'),
    address: parseString(json, 'address'),
    geoPoint: parseGeoPoint(json, 'geoPoint'),
    title: parseString(json, 'title'),
    isApproved: parseBooleanIfPresent(json, 'isApproved'),
    mapImageUrl: parseString(json, 'mapImageUrl'),
    read: parseBoolean(json, 'read'),
    canViewerEdit: parseBoolean(json, 'canViewerEdit'),
    deadline: parseDateIfPresent(json, 'deadline'),
  }
}

export interface UserMarkerContentData {
  readonly title: string
  readonly content: string
  readonly imageUrl?: string
  readonly startDate?: Date
  readonly endDate?: Date
  readonly videoUrl?: string
  readonly deadline?: Date
}

export function parseUserMarkerContentData(json: any): UserMarkerContentData {
  return {
    title: parseNonEmptyString(json, 'title'),
    content: parseString(json, 'content'),
    imageUrl: parseStringIfPresent(json, 'imageUrl'),
    startDate: parseDateIfPresent(json, 'startDate'),
    endDate: parseDateIfPresent(json, 'endDate'),
    videoUrl: parseStringIfPresent(json, 'videoUrl'),
    deadline: parseDateIfPresent(json, 'deadline'),
  }
}

export interface UserMarkerContent extends UserMarkerContentData {
  readonly namespace?: string
  // ключ идемпотентности, чтобы одна и та же точка не добавилась 2 раза
  readonly key?: string
  readonly address: string // TODO: почему тут нет ?
  readonly geoPoint: GeoPoint
  // пользователь может передать дату при создании точки
  // необходимо для корректного отображения даты у офлайн точек
  readonly createdAt?: Date
}

export function parseUserMarkerContent(json: any): UserMarkerContent {
  return {
    ...parseUserMarkerContentData(json),
    namespace: parseStringIfPresent(json, 'namespace'),
    key: parseStringIfPresent(json, 'key'),
    address: parseString(json, 'address'),
    geoPoint: parseGeoPoint(json, 'geoPoint'),
    createdAt: parseDateIfPresent(json, 'createdAt'),
  }
}

export interface AddMarkerRequest extends UserMarkerContent {
  readonly channelId: ID
  readonly authorId: ID
  readonly sourceUrl?: string
  readonly ticketUrl?: string
  readonly isApproved?: boolean
}

export interface Marker extends MarkerView, AddMarkerRequest {
  readonly createdAt: Date
  readonly updatedAt: Date
  readonly author: User
  readonly viewerLiked: boolean
  readonly likesTotal: number
  readonly commentsEnabled: boolean
  readonly commentsTotal: number
  readonly contentHTML: string
}

export interface MetrofanObject {
  readonly fid: ID
  readonly address?: string
  readonly years?: string
  readonly architect?: string
  readonly style?: string
  readonly name?: string
  readonly photoUrl?: string
  readonly cwUrl?: string
  readonly layer?: string
  readonly year?: number
  readonly url?: string
  readonly copyrights?: string
  readonly wikipediaUrl?: string
  readonly border: GeoPoint[]
}

export interface MetrofanObjectView extends MetrofanObject {
  readonly center: GeoPoint
  readonly distanceMeters: number
}

export function isEmptyMetrofanObject(obj: MetrofanObject): boolean {
  return (
    !obj.address &&
    !obj.years &&
    !obj.architect &&
    !obj.style &&
    !obj.name &&
    !obj.photoUrl &&
    !obj.cwUrl &&
    !obj.year &&
    !obj.url &&
    !obj.wikipediaUrl
  )
}

export function parseMetrofanObjectView(json: any): MetrofanObjectView {
  return {
    fid: parseNumber(json, 'fid'),
    address: parseString(json, 'address'),
    years: parseStringIfPresent(json, 'years'),
    architect: parseStringIfPresent(json, 'architect'),
    style: parseStringIfPresent(json, 'style'),
    name: parseStringIfPresent(json, 'name'),
    photoUrl: parseStringIfPresent(json, 'photoUrl'),
    cwUrl: parseStringIfPresent(json, 'cwUrl'),
    layer: parseString(json, 'layer'),
    year: parseNumberIfPresent(json, 'year'),
    url: parseStringIfPresent(json, 'url'),
    copyrights: parseStringIfPresent(json, 'copyrights'),
    wikipediaUrl: parseStringIfPresent(json, 'wikipediaUrl'),
    border: parseArray(json, 'border').map((el) => parseGeoPoint(el)),
    center: parseGeoPoint(json, 'center'),
    distanceMeters: parseNumber(json, 'distanceMeters'),
  }
}

export interface Compilation {
  readonly id: ID
  readonly channelId: ID
  readonly name: string
  readonly authorId: ID
}

// обратная операция к JSON.stringify()

export function parseCompilation(json: any): Compilation {
  return {
    id: parseNumber(json, 'id'),
    channelId: parseNumber(json, 'channelId'),
    name: parseString(json, 'name'),
    authorId: parseNumber(json, 'authorId'),
  }
}

export function parseMarker(json: any): Marker {
  return {
    ...parseMarkerView(json),
    ...parseUserMarkerContent(json),
    channelId: parseNumber(json, 'channelId'),
    authorId: parseNumber(json, 'authorId'),
    createdAt: parseDate(json, 'createdAt'),
    updatedAt: parseDate(json, 'updatedAt'),
    sourceUrl: parseStringIfPresent(json, 'sourceUrl'),
    ticketUrl: parseStringIfPresent(json, 'ticketUrl'),
    author: parseUser(json, 'author'),
    viewerLiked: parseBoolean(json, 'viewerLiked'),
    likesTotal: parseNumber(json, 'likesTotal'),
    commentsEnabled: parseBoolean(json, 'commentsEnabled'),
    commentsTotal: parseNumber(json, 'commentsTotal'),
    contentHTML: parseString(json, 'contentHTML'),
  }
}

export interface User {
  readonly id: number
  readonly login: string
  readonly isAdmin: boolean
  readonly name?: string
  readonly avatarUrl?: string
  readonly isBanned: boolean
  readonly email?: string
}

export function parseUser(json: any, key?: string): User {
  if (key) {
    return parseUser(requireField(json, key))
  }
  return {
    id: parseNumber(json, 'id'),
    login: parseString(json, 'login'),
    isAdmin: parseBoolean(json, 'isAdmin'),
    name: parseStringIfPresent(json, 'name'),
    avatarUrl: parseStringIfPresent(json, 'avatarUrl'),
    isBanned: parseBoolean(json, 'isBanned'),
    email: parseStringIfPresent(json, 'email'),
  }
}

export interface CommentData {
  readonly text: string
  readonly imageUrl?: string
}

export function parseCommentData(json: any): CommentData {
  return {
    text: parseString(json, 'text'),
    imageUrl: parseStringIfPresent(json, 'imageUrl'),
  }
}

export interface Comment extends CommentData {
  readonly id: ID
  readonly markerId: ID
  readonly user: User
  readonly date: Date
  readonly viewerLiked: boolean
  readonly likesTotal: number
}

export function parseComment(json: any): Comment {
  return {
    ...parseCommentData(json),
    id: parseNumber(json, 'id'),
    markerId: parseNumber(json, 'markerId'),
    user: parseUser(json, 'user'),
    date: parseDate(json, 'date'),
    viewerLiked: parseBoolean(json, 'viewerLiked'),
    likesTotal: parseNumber(json, 'likesTotal'),
  }
}

export function parseDraftPoint(row: any): InteractiveMapDraftPoint {
  return {
    id: parseNumber(row, 'id'),
    geoPoint: parseGeoPoint(row, 'geoPoint'),
    imagePoint: parsePoint(row, 'imagePoint'),
    mapId: parseNumber(row, 'mapId'),
    authorId: parseNumber(row, 'authorId'),
  }
}

export function parseSnapshot(row: any): InteractiveMapModelSnapshot {
  return {
    id: parseNumber(row, 'id'),
    name: parseString(row, 'name'),
    image: parseImageWithSize(row, 'image'),
    mapId: parseNumber(row, 'mapId'),
    model: parseDelaunayModel(requireField(row, 'model')),
    viewerCanEdit: parseBoolean(row, 'viewerCanEdit'),
  }
}

export function parseInteractiveMap(row: any): InteractiveMap {
  return {
    id: parseNumber(row, 'id'),
    name: parseString(row, 'name'),
    image: parseImageWithSize(row, 'image'),
    mapThumbnailUrl: parseString(row, 'mapThumbnailUrl'),
    activeSnapshotId: parseNumberIfPresent(row, 'activeSnapshotId'),
  }
}

export interface ReportCause {
  readonly id: ID
  readonly name: string // TODO: deprecated
  readonly localizedName: LocalizedString
}

export function parseReportCause(json: any): ReportCause {
  return {
    id: parseNumber(json, 'id'),
    name: parseString(json, 'name'),
    localizedName: parseDictionary(json, 'localizedName'),
  }
}

export interface ComplainStatus {
  readonly id: ID
  readonly name: string // TODO: deprecated
  readonly localizedName: LocalizedString
  readonly entityType: string
  readonly pinzmeAdminVerification?: boolean
}

export function parseComplainStatus(json: any): ComplainStatus {
  return {
    id: parseNumber(json, 'id'),
    name: parseString(json, 'name'),
    localizedName: parseDictionary(json, 'localizedName'),
    entityType: parseString(json, 'entityType'),
    pinzmeAdminVerification: parseBooleanIfPresent(json, 'pinzmeAdminVerification'),
  }
}

export interface ComplainEntity {
  readonly type: string
  readonly id: ID
}

export function parseComplainEntity(json: any): ComplainEntity {
  return {
    type: parseNonEmptyString(json, 'type'),
    id: parseNumber(json, 'id'),
  }
}

export interface ShareItem {
  readonly filePath?: string
  readonly text?: string
  readonly weblink?: string
  readonly mimeType?: string
  readonly contentUri?: string
  readonly fileName?: string
  readonly extension?: string
}

export function parseShareItem(json: any): ShareItem {
  return {
    filePath: parseStringIfPresent(json, 'filePath'),
    text: parseStringIfPresent(json, 'text'),
    weblink: parseStringIfPresent(json, 'weblink'),
    mimeType: parseStringIfPresent(json, 'mimeType'),
    contentUri: parseStringIfPresent(json, 'contentUri'),
    fileName: parseStringIfPresent(json, 'fileName'),
    extension: parseStringIfPresent(json, 'extension'),
  }
}
