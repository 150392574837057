import {
  ChannelParticipant,
  ChannelView,
  Comment,
  CommentData,
  Compilation,
  ComplainStatus,
  GeoPoint,
  ID,
  Marker,
  MarkerView,
  parseChannel,
  parseChannelParticipant,
  parseComment,
  parseCompilation,
  parseComplainStatus,
  parseInteractiveMap,
  parseMarker,
  parseMarkerView,
  parseReportCause,
  parseUser,
  ReportCause,
  User,
  UserChannelInfo,
  UserMarkerContent,
  parseDraftPoint,
  Point,
  parseSnapshot,
  ComplainEntity,
  ShareItem,
  UserMarkerContentData,
  parseUserMarkerContentData,
  MetrofanObjectView,
  parseMetrofanObjectView,
} from './model'
import { TokenProvider } from './token-provider'
import { parseArray, parseBoolean, parseNonEmptyString, parseNumber, parseString, requireField } from './parsing'
import { PinzMe } from './pinzme'
import { InteractiveMap, InteractiveMapModelSnapshot, InteractiveMapView, parseInteractiveMapView } from './map-model'
import { InteractiveMapDraftPoint } from './map-model'
import { BackendError, UnauthorizedError } from './error'
import { AppInfo, serializeToHeaders } from './app-info'

export class PinzmeClient implements PinzMe {
  constructor(
    private readonly baseUrl: string,
    private readonly tokenProvider: TokenProvider,
    private readonly appInfo: AppInfo,
  ) {}

  public async changePassword(oldPassword: string, password: string): Promise<void> {
    await this.postRequest('/user/changePassword', { oldPassword, password })
  }

  public async changePasswordByEncryptedUser(newPassword: string, token: string): Promise<void> {
    await this.postRequest('/user/changePasswordByEncryptedUser', { newPassword, token })
  }

  public async login(login: string, password: string): Promise<string> {
    const json = await this.postRequest('/login', { login, password }, true)
    const token = parseNonEmptyString(json, 'token')
    await this.tokenProvider.setToken(token)
    return token
  }

  public async restore(email: string): Promise<void> {
    await this.postRequest('/restore', { email })
  }

  public async isAuthorized(): Promise<boolean> {
    const token = await this.tokenProvider.provideToken()
    return token !== undefined
  }

  public async whoami(): Promise<User> {
    const json = await this.getRequest('/whoami')
    return parseUser(json)
  }

  public async register(login: string, password: string, recaptchaToken: string): Promise<ID> {
    const json = await this.postRequest('/register', { login, password, recaptchaToken })
    return parseNumber(json, 'id')
  }

  public async updateName(name: string): Promise<void> {
    await this.postRequest('/user/update-name', { name }, true)
  }

  public async updateLogin(login: string): Promise<void> {
    await this.postRequest('/user/update-login', { login }, true)
  }

  public async updateEmail(email?: string): Promise<void> {
    await this.postRequest('/user/update-email', { email }, true)
  }

  public async updateNotificationToken(notificationToken: string): Promise<void> {
    await this.postRequest(
      '/notification/token',
      {
        notificationToken,
      },
      true,
    )
  }

  public async logout(): Promise<void> {
    await this.getRequest('/logout')
    await this.tokenProvider.removeToken()
  }

  public async newInteractiveMap(name: string, imageUrl: string): Promise<ID> {
    const json = await this.postRequest('/map/add', { name, imageUrl })
    return parseNumber(json, 'mapId')
  }

  public async deleteInteractiveMap(id: ID): Promise<void> {
    await this.deleteRequest(`/interactive-map/${id}`)
  }

  public async deleteDraft(mapId: ID): Promise<void> {
    await this.postRequest('/draft/delete', { mapId })
  }

  public async getAllInteractiveMaps(): Promise<InteractiveMap[]> {
    const json = await this.getRequest('/map/getAll')
    console.log(json)
    return parseArray(json, 'maps').map((el) => parseInteractiveMap(el))
  }

  public async getInteractiveMapById(mapId: ID): Promise<InteractiveMap> {
    const json = await this.getRequest(`/map/get/id/${mapId}`)
    return parseInteractiveMap(requireField(json, 'map'))
  }

  public async editInteractiveMap(mapId: ID): Promise<void> {
    await this.postRequest(`/map/edit/id/${mapId}`)
  }

  public async submitInteractiveMap(mapId: ID): Promise<ID> {
    const json = await this.postRequest(`/map/submit/id/${mapId}`)
    return parseNumber(json, 'snapshotId')
  }

  public async getSnapshotById(snapshotId: ID): Promise<InteractiveMapModelSnapshot> {
    const json = await this.getRequest(`/snapshot/get/${snapshotId}`)
    return parseSnapshot(requireField(json, 'snapshot'))
  }

  public async addDraftPoint(geoPoint: GeoPoint, imagePoint: Point, mapId: ID): Promise<ID> {
    const json = await this.postRequest('/draft/point/add', { geoPoint, imagePoint, mapId })
    return parseNumber(json, 'pointId')
  }

  public async editDraftPoint(pointId: ID, geoPoint: GeoPoint, imagePoint: Point): Promise<void> {
    await this.postRequest('/draft/point/edit', { pointId, geoPoint, imagePoint })
  }

  public async deleteDraftPoint(pointId: ID): Promise<void> {
    await this.postRequest('/draft/point/delete', { pointId })
  }

  public async getAllDraftPoints(mapId: ID): Promise<InteractiveMapDraftPoint[]> {
    const json = await this.getRequest(`/draft/${mapId}/getAll`)
    return parseArray(json, 'points').map((el) => parseDraftPoint(el))
  }

  public async getChannelInteractiveMaps(channelId: ID): Promise<InteractiveMapView[]> {
    const json = await this.getRequest(`/channel/${channelId}/interactive-maps`)
    return parseArray(json, 'interactiveMaps').map((el) => parseInteractiveMapView(el))
  }

  private parseCommentsJson(commentsJson: any[]): Comment[] {
    return commentsJson.map((comment) => parseComment(comment))
  }

  private parseMarkersJson(markersJson: any[]): Marker[] {
    return markersJson.map(parseMarker)
  }

  private parseMarkerViewsJson(markersViewsJson: any[]): MarkerView[] {
    return markersViewsJson.map((markerView) => parseMarkerView(markerView))
  }

  private parseChannelParticipantsJson(channelParticipants: any[]): ChannelParticipant[] {
    return channelParticipants.map((channelParticipant) => parseChannelParticipant(channelParticipant))
  }

  private parseCompilations(compsJson: any[]): Compilation[] {
    return compsJson.map((comp) => parseCompilation(comp))
  }

  private parseChannels(channelsJson: any[]): ChannelView[] {
    return channelsJson.map((channel) => parseChannel(channel))
  }

  public async validateLogin(login: string): Promise<boolean> {
    const json = await this.postRequest('/login/validate', { login }, true)
    return parseBoolean(json, 'ok')
  }

  public async validateChannelKey(key: string, channelId?: ID): Promise<boolean> {
    if (!key.trim().length) {
      return false
    }
    const json = await this.postRequest(
      '/channel/validate',
      {
        channelKey: key,
        channelId,
      },
      true,
    )
    return parseBoolean(json, 'valid')
  }

  public async getUncheckedMarkers(channelId: ID): Promise<Marker[]> {
    const markersJson = await this.getRequest(`/markers/get-unchecked/${channelId}`)
    return this.parseMarkersJson(markersJson)
  }

  public async getComplainedMarkers(): Promise<Marker[]> {
    const markersJson = await this.getRequest('/markers/complained')
    return this.parseMarkersJson(markersJson)
  }

  public async approveMarker(id: ID): Promise<void> {
    await this.put(`/marker/${id}/approve`)
  }

  public async declineMarker(id: ID): Promise<void> {
    await this.deleteRequest(`/marker/${id}/approve`)
  }

  public async getMarkerById(id: ID): Promise<Marker> {
    const markerJson = await this.getRequest(`/marker/${id}`)
    return parseMarker(markerJson)
  }

  public async uploadPhoto(base64: string): Promise<string> {
    const url = await this.postRequest('/upload', {
      data: base64,
    })
    return parseString(url, 'imageUrl')
  }

  public async setAvatar(url: string): Promise<string> {
    await this.postRequest('/user/set-avatar', { url })
    return url
  }

  public async addMarker(channelId: ID, marker: UserMarkerContent): Promise<ID> {
    const json = await this.put(`/channel/${channelId}/marker`, marker, true)
    return parseNumber(json, 'markerId')
  }

  public async modifyMarkerGeoPoint(markerId: ID, geoPoint: GeoPoint): Promise<ID> {
    const json = await this.postRequest(`/marker/${markerId}/location`, geoPoint, true)
    return parseNumber(json, 'markerId')
  }

  public async modifyMarker(markerId: ID, marker: UserMarkerContent): Promise<ID> {
    const json = await this.postRequest(`/marker/${markerId}`, marker, true)
    return parseNumber(json, 'markerId')
  }

  public async deleteMarker(markerId: ID): Promise<void> {
    await this.deleteRequest(`/marker/${markerId}`)
  }

  public async like(markerId: ID): Promise<void> {
    await this.put(`/marker/${markerId}/like`)
  }

  public async unlike(markerId: ID): Promise<void> {
    await this.deleteRequest(`/marker/${markerId}/like`)
  }

  public async getMyLikes(): Promise<Marker[]> {
    const json = await this.getRequest('/likes/get-markers')
    return this.parseMarkersJson(json)
  }

  public async getComments(markerId: ID): Promise<Comment[]> {
    const json = await this.getRequest(`/comments?marker=${markerId}`)
    return this.parseCommentsJson(json)
  }

  public async comment(markerId: ID, data: CommentData): Promise<ID> {
    const json = await this.put(`/marker/${markerId}/comment`, data)
    return parseNumber(json, 'commentId')
  }

  public async deleteComment(commentId: ID): Promise<void> {
    await this.deleteRequest(`/comment/${commentId}`)
  }

  public async editComment(commentId: ID, text: string): Promise<void> {
    await this.postRequest(`/comment/${commentId}`, { text })
  }

  public async likeComment(commentId: ID): Promise<void> {
    await this.put(`/comment/${commentId}/like`)
  }

  public async unlikeComment(commentId: ID): Promise<void> {
    await this.deleteRequest(`/comment/${commentId}/like`)
  }

  public async createCompilation(channelId: ID, compilationName: string): Promise<ID> {
    const json = await this.postRequest('/compilation/add', {
      channelId,
      name: compilationName,
    })
    return parseNumber(json, 'id')
  }

  public async deleteCompilation(compilationId: ID): Promise<void> {
    await this.deleteRequest('/compilation/delete', { id: compilationId })
  }

  public async getChannelCompilations(channelId: ID): Promise<Compilation[]> {
    const json = await this.getRequest(`/compilation/channel/${channelId}`)
    return this.parseCompilations(json.compilations)
  }

  public async getCompilationMarkers(compilationId: ID): Promise<MarkerView[]> {
    const json = await this.getRequest(`/compilation/${compilationId}/info`)
    return this.parseMarkerViewsJson(json.compilation)
  }

  public async getChannelMarkers(channelId: ID, unread?: boolean): Promise<MarkerView[]> {
    let url = `/channel/markers/${channelId}`
    if (unread !== undefined) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      url += `?unread=${unread}`
    }
    const json = await this.getRequest(url)
    return this.parseMarkerViewsJson(json.markers)
  }

  public async getMetrofanObjects(center: GeoPoint): Promise<MetrofanObjectView[]> {
    const json = await this.postRequest('/metrofan/objects', center)
    const objects = parseArray(json, 'objects')
    return objects.map((o) => parseMetrofanObjectView(o))
  }

  public async addMarkerToCompilation(compId: ID, markerId: ID): Promise<void> {
    await this.postRequest('/compilation/marker/add', { compilation: compId, marker: markerId })
  }

  public async deleteMarkerFromCompilation(compId: ID, markerId: ID): Promise<void> {
    await this.deleteRequest('/compilation/marker/delete', { compilation: compId, marker: markerId })
  }

  public async getUserChannels(): Promise<ChannelView[]> {
    const json = await this.getRequest('/channel/user/')
    return this.parseChannels(parseArray(json, 'channels'))
  }

  public async getChannelsToPostMarker(): Promise<ChannelView[]> {
    const json = await this.getRequest('/channels/post')
    return this.parseChannels(parseArray(json, 'channels'))
  }

  public async cloneCompilation(compilationId: ID, destinationChannelId: ID): Promise<ID> {
    const json = await this.postRequest('/compilation/clone', { id: compilationId, destinationChannelId })
    return parseNumber(json, 'id')
  }

  public async searchChannels(channelPrefix: string): Promise<ChannelView[]> {
    const json = await this.postRequest('/search/channels', {
      prefix: channelPrefix,
    })
    return this.parseChannels(json)
  }

  public async getCompilationByMarker(markerId: ID): Promise<Compilation | undefined> {
    const json = await this.getRequest(`/marker/${markerId}/compilation`)
    if (parseString(json, 'status') !== 'ok') {
      return
    }
    return parseCompilation(json.compilation)
  }

  public async cloneMarker(markerId: ID, destinationChannelId: ID): Promise<ID> {
    const json = await this.postRequest(`/marker/${markerId}/clone`, { destinationChannelId })
    return parseNumber(json, 'id')
  }

  public async createChannel(info: UserChannelInfo): Promise<ID> {
    const json = await this.postRequest('/channel/add', info)
    return parseNumber(json, 'id')
  }

  public async modifyChannel(channelId: ID, info: UserChannelInfo): Promise<void> {
    const body = { id: channelId, info }
    await this.postRequest('/channel/modify', body, true)
  }

  public async joinChannel(channelKey: string): Promise<ID> {
    const json = await this.postRequest('/channel/join', { channelKey }, true)
    return parseNumber(json, 'id')
  }

  public async leaveChannel(channelId: ID): Promise<void> {
    await this.postRequest(`/channel/${channelId}/leave`, true)
  }

  public async getOrGenerateChannelKey(channelId?: ID): Promise<string> {
    const json = await this.postRequest(
      '/channel/key',
      {
        channelId,
      },
      true,
    )
    return parseNonEmptyString(json, 'channelKey')
  }

  public async getChannelUsers(channelId: ID, offset: number, limit: number): Promise<ChannelParticipant[]> {
    const body = { limit, offset }
    const json = await this.postRequest(`/channel/get-users/${channelId}`, body)
    return this.parseChannelParticipantsJson(json.users)
  }

  public async getChannelById(channelId: ID): Promise<ChannelView> {
    const json = await this.getRequest(`/channel/get/${channelId}`)
    return parseChannel(json.channel)
  }

  async mute(channelId: ID): Promise<void> {
    await this.postRequest(`/channel/${channelId}/notify?notify=false`, true)
  }

  async unmute(channelId: ID): Promise<void> {
    await this.postRequest(`/channel/${channelId}/notify?notify=true`, true)
  }

  async read(markerId: ID): Promise<void> {
    await this.postRequest(`/marker/${markerId}/read`, true)
  }

  async getComplainCauses(): Promise<ReportCause[]> {
    const json = await this.getRequest('/report-causes')
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return json.map(parseReportCause)
  }

  async reportComplain(markerId: ID, causeId: ID): Promise<ID> {
    const json = await this.postRequest(`/marker/${markerId}/report`, {
      complainCauseId: causeId,
    })
    return parseNumber(json, 'id')
  }

  async reportCommentComplain(commentId: ID, causeId: ID): Promise<ID> {
    const json = await this.postRequest(`/comment/${commentId}/report`, {
      complainCauseId: causeId,
    })
    return parseNumber(json, 'id')
  }

  async reportEntityComplain(entity: ComplainEntity, causeId: ID): Promise<ID> {
    const json = await this.postRequest(`/report-cause/${causeId}/report`, entity)
    return parseNumber(json, 'id')
  }

  async getMyComplains(): Promise<ComplainStatus[]> {
    const json = (await this.getRequest('/complains')) as any[]
    return json.map((element) => parseComplainStatus(element))
  }

  async isBlocked(abuserId: ID): Promise<boolean> {
    const json = await this.getRequest(`/user/block/${abuserId}`)
    return parseBoolean(json, 'doesViewerBlocked')
  }

  async block(abuserId: ID): Promise<void> {
    await this.postRequest(`/user/block/${abuserId}`)
  }

  async unblock(abuserId: ID): Promise<void> {
    await this.postRequest(`/user/unblock/${abuserId}`)
  }

  async hideMarker(markerId: ID): Promise<void> {
    await this.postRequest(`/marker/${markerId}/hide`)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async log(data: any): Promise<void> {
    await this.postRequest('/log', data)
  }

  async computeMarker(shareItems: ShareItem[]): Promise<UserMarkerContentData> {
    const json = await this.postRequest('/compute-marker', { shareItems }, true)
    return parseUserMarkerContentData(json)
  }

  async deleteAccount(): Promise<void> {
    await this.postRequest('/delete-account')
  }

  public async ping(): Promise<void> {
    await this.getRequest('/ping')
  }

  private async getRequest(url: string): Promise<any> {
    return this.request('GET', url, undefined, true)
  }

  private async put(url: string, body?: any, canRetry?: boolean): Promise<any> {
    return this.request('PUT', url, body, canRetry)
  }

  private async postRequest(url: string, body?: any, canRetry?: boolean): Promise<any> {
    return this.request('POST', url, body, canRetry)
  }

  private async deleteRequest(url: string, body?: any): Promise<any> {
    return this.request('DELETE', url, body)
  }

  private async request(method: string, url: string, body?: any, canRetry?: boolean): Promise<any> {
    const fullUrl = `${this.baseUrl}/api${url}`
    const headers: { [key: string]: string } = {
      Accept: 'application/json',
    }
    if (body) {
      headers['Content-Type'] = 'application/json'
    }
    const token: string | undefined = await this.tokenProvider.provideToken()
    if (token) {
      headers.Authorization = `OAuth ${token}`
    }
    for (const [name, value] of Object.entries(serializeToHeaders(this.appInfo))) {
      headers[name] = value
    }

    console.log(`curl -X ${method} '${fullUrl}'`)
    const response = await this.fetchPlus(
      fullUrl,
      {
        method,
        headers,
        body: body ? JSON.stringify(body) : undefined,
      },
      canRetry ? 3 : 1,
    )
    if (response.status === 404) {
      throw new Error('Такого метода нет на бэкенде')
    }
    const status = Math.floor(response.status / 100)
    if (status === 2) {
      console.log(`Request ${fullUrl} finished successfully`)
      return response.json()
    }
    const errorMessage = await response.text()
    console.error(`Request ${fullUrl} failed with status ${response.status} and text ${errorMessage}`)
    if (response.status === 401) {
      throw new UnauthorizedError()
    }
    const backendError = BackendError.parse(errorMessage)
    if (backendError) {
      throw backendError
    }
    throw new Error(errorMessage)
  }

  private async fetchPlus(url: string, options: RequestInit, tries: number): Promise<Response> {
    try {
      const response = await fetch(url, options)
      if (response.status / 100 !== 5 || tries === 1) {
        return response
      }
    } catch (err) {
      console.error(err)
      if (tries === 1) {
        throw err
      }
    }
    return this.fetchPlus(url, options, tries - 1)
  }
}
