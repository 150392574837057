/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { GeoPoint, ID, Point } from '../model'
import { parseArray } from '../parsing'
import { InteractiveMapModel, parsePointMapping, PointMapping } from '../map-model'
import { Delaunay } from 'd3-delaunay'
import { InteractiveLinearMapModel, trainModel } from './linear-map-model'

export interface DelaunayModel {
  readonly points: PointMapping[]
}

export function parseDelaunayModel(json: any): DelaunayModel {
  return {
    points: parseArray(json, 'points').map((el) => parsePointMapping(el)),
  }
}

export class DelaunayMapModel implements InteractiveMapModel {
  private readonly delaunay: Delaunay<GeoPoint>

  constructor(private readonly model: DelaunayModel) {
    this.delaunay = Delaunay.from(
      model.points,
      (p) => p.geoPoint.latitude,
      (p) => p.geoPoint.longitude,
    )
  }

  apply(geoPoint: GeoPoint): Point {
    const triangle = this.findTriangle(geoPoint)
    const points = triangle.map((v) => this.model.points[v])
    const linearModel = trainModel(points)
    return new InteractiveLinearMapModel(linearModel).apply(geoPoint)
  }

  private findTriangle(geoPoint: GeoPoint): ID[] {
    const n = Math.floor(this.delaunay.triangles.length / 3)
    for (let i = 0; i < n; i++) {
      const [v1, v2, v3] = this.getTriangle(i)
      const [p1, p2, p3] = [v1, v2, v3].map((v) => this.getPoint(v))
      if (this.pointInTriangle(this.convert(geoPoint), p1, p2, p3)) {
        return [v1, v2, v3]
      }
    }
    const closest = this.delaunay.find(geoPoint.latitude, geoPoint.longitude)
    const e = this.delaunay.inedges[closest]
    const t = Math.floor(e / 3)
    return this.getTriangle(t)
  }

  private convert(geoPoint: GeoPoint): Point {
    return {
      x: geoPoint.latitude,
      y: geoPoint.longitude,
    }
  }

  private getPoint(i: ID): Point {
    const [x, y] = Array.from(this.delaunay.points).slice(2 * i, 2 * i + 2)
    return { x, y }
  }

  private getTriangle(i: ID): ID[] {
    return Array.from(this.delaunay.triangles.slice(3 * i, 3 * i + 3))
  }

  private sign(p1: Point, p2: Point, p3: Point): number {
    return (p1.x - p3.x) * (p2.y - p3.y) - (p2.x - p3.x) * (p1.y - p3.y)
  }

  private pointInTriangle(pt: Point, v1: Point, v2: Point, v3: Point): boolean {
    const d1 = this.sign(pt, v1, v2)
    const d2 = this.sign(pt, v2, v3)
    const d3 = this.sign(pt, v3, v1)
    const hasNeg = d1 < 0 || d2 < 0 || d3 < 0
    const hasPos = d1 > 0 || d2 > 0 || d3 > 0
    return !(hasNeg && hasPos)
  }
}
