/* eslint-disable */
import { GeoPoint, Point } from '../model'
import { InteractiveMapModel, PointMapping } from "../map-model";
import { parseNumber, requireField } from '../parsing'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { multiply, inv, jStat } from 'jstat'

export interface LinearModel {
  readonly a: number
  readonly b: number
  readonly c: number
}

export interface LinearMapModel {
  readonly xModel: LinearModel
  readonly yModel: LinearModel
}

export class InteractiveLinearMapModel implements InteractiveMapModel {
  constructor(private readonly lMM: LinearMapModel) {}

  public apply(geoPoint: GeoPoint): Point {
    return {
      x: this.applyModel(geoPoint, this.lMM.xModel),
      y: this.applyModel(geoPoint, this.lMM.yModel),
    }
  }

  private applyModel(geoPoint: GeoPoint, model: LinearModel): number {
    return geoPoint.latitude * model.a + geoPoint.longitude * model.b + model.c
  }
}

function parseLinearModel(json: any): LinearModel {
  return {
    a: parseNumber(json, 'a'),
    b: parseNumber(json, 'b'),
    c: parseNumber(json, 'c'),
  }
}

export function parseLinearMapModel(json: any): LinearMapModel {
  return {
    xModel: parseLinearModel(requireField(json, 'xModel')),
    yModel: parseLinearModel(requireField(json, 'yModel')),
  }
}


/**
 * Train a linear model based on all points of a map.
 *
 * Uses the following formula:
 * x = (A^T * A)^-1 * A^T * b
 * where A = [[lat1, lon1, 1], [lat2, lon2, 1], ...]
 * b = [x1, x2, ...]
 * x = [a, b, c] -- the resulting linear model
 *
 * Should not be applied to two points
 * @param allPoints - точки для обучения модели
 */
export function trainModel(allPoints: PointMapping[]): LinearMapModel {
  const stub: number[][] = []
  const bX: number[][] = []
  const bY: number[][] = []
  allPoints.forEach((point) => {
    stub.push([point.geoPoint.latitude, point.geoPoint.longitude, 1])
    bX.push([point.imagePoint.x])
    bY.push([point.imagePoint.y])
  })
  const A = jStat(stub)
  const AT = A.transpose()
  const tmp = jStat(multiply(inv(multiply(AT, A)), AT))
  const ansX = multiply(tmp, bX)
  const ansY = multiply(tmp, bY)

  return {
    xModel: { a: ansX[0][0], b: ansX[1][0], c: ansX[2][0] },
    yModel: { a: ansY[0][0], b: ansY[1][0], c: ansY[2][0] },
  }
}
