import { ID, Point } from '@pinzme/common/src/model'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { pinzmeWeb } from '@pinzme/components/src/registry'
import {
  Image,
  ImageBackground,
  Dimensions,
  View,
  Modal,
  StyleSheet,
  Pressable,
  Text,
  TouchableOpacity,
  Animated,
  Easing,
} from 'react-native'
import { InteractiveMapModelSnapshot } from '@pinzme/common/src/map-model'
import { DelaunayMapModel } from '@pinzme/common/src/interactive-map/delaunay-model'
import { watchPosition } from '@pinzme/components/src/utils'
import {
  computeImageScreenHeight,
  computePointPosition,
  pointImageUrl,
} from '@pinzme/components/src/common-components-utils'
import { centeredActivityIndicator } from './web-utils'

export function WebInteractiveMapComponent({ snapshotId }: { snapshotId: ID }): ReactElement {
  const [snapshot, setSnapshot] = useState<InteractiveMapModelSnapshot | undefined>()

  const [currentPosition, setCurrentPosition] = useState<Point | undefined>()
  const animatedPosition = useRef(new Animated.ValueXY()).current

  useEffect(() => {
    const listenerId = animatedPosition.addListener(setCurrentPosition)
    return () => {
      animatedPosition.removeListener(listenerId)
    }
  }, [])

  const [showPositionRequestHint, setShowPositionRequestHint] = useState<boolean>(false)
  const [locationEnabled, setLocationEnabled] = useState<boolean | undefined>()
  const [userAgreed, setUserAgreed] = useState<boolean>(false)

  useEffect(() => {
    void pinzmeWeb.getSnapshotById(snapshotId).then(setSnapshot)
  }, [snapshotId])

  useEffect(() => {
    if (!snapshot || !(locationEnabled || userAgreed)) {
      return
    }
    const lm = new DelaunayMapModel(snapshot.model)
    return watchPosition(
      (geoPosition) => {
        const imagePosition = lm.apply(geoPosition)
        Animated.timing(animatedPosition, {
          toValue: imagePosition,
          duration: 1000,
          useNativeDriver: false,
          easing: Easing.linear,
        }).start()
      },
      (error) => {
        console.error(error)
      },
    )
  }, [snapshot, locationEnabled, userAgreed])

  useEffect(() => {
    if (!navigator.permissions) {
      // в safari не проверить пермишен на геопозицию без ее запроса
      // поэтому мы всегда показываем popup
      // TODO: сохранять показ popup где-то в куках?
      setLocationEnabled(false)
      return
    }
    void navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      setLocationEnabled(result.state === 'granted')
    })
  }, [])

  useEffect(() => {
    if (locationEnabled !== false) {
      return
    }
    setShowPositionRequestHint(true)
  }, [locationEnabled])

  if (!snapshot) {
    return centeredActivityIndicator()
  }

  const image = snapshot.image
  const imageScreenWidth = Dimensions.get('window').width
  const imageScreenHeight = computeImageScreenHeight(imageScreenWidth, image.size)

  return (
    <View>
      <Modal animationType={'slide'} transparent={true} visible={showPositionRequestHint}>
        <TouchableOpacity
          onPress={() => {
            setShowPositionRequestHint(false)
            setUserAgreed(true)
          }}
          style={styles.centeredView}
        >
          <View style={styles.modalView}>
            <Text
              style={{
                marginBottom: 30,
                textAlign: 'center',
              }}
            >
              Если вы разрешите использовать геопозцию, то мы сможем в режиме реального времени показывать таким
              значком, где вы сейчас на этой карте.
            </Text>
            <Image style={{ width: 50, height: 100 }} source={{ uri: pointImageUrl }} resizeMode={'contain'} />
            <Pressable
              onPress={() => {
                setShowPositionRequestHint(false)
                setUserAgreed(true)
              }}
            >
              <Text style={{ fontWeight: 'bold', textAlign: 'center' }}>Хорошо</Text>
            </Pressable>
          </View>
        </TouchableOpacity>
      </Modal>
      <ImageBackground source={{ uri: image.url }} style={{ width: imageScreenWidth, height: imageScreenHeight }} />
      {currentPosition ? (
        <Image
          style={computePointPosition(currentPosition, 1, image.size, imageScreenWidth, imageScreenHeight)}
          source={{ uri: pointImageUrl }}
          resizeMode={'contain'}
        />
      ) : undefined}
    </View>
  )
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
})
