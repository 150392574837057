import { ReactElement } from 'react'
import './app.css'
import { WebInteractiveMapScreen } from './web-interactive-map-screen'
import { Route, Switch } from 'react-router-dom'
import { MapScreenComponent } from './map-screen'
import { MordaComponent } from './morda-component'

function App(): ReactElement {
  return (
    <Switch>
      <Route path="/map/:id" component={WebInteractiveMapScreen} />
      <Route path="/marker/:markerId" component={MapScreenComponent} />
      <Route path="/channel/:channelId" component={MapScreenComponent} />
      <Route component={MordaComponent} />
    </Switch>
  )
}

export default App
