import { GeoPoint } from '@pinzme/common/src/model'
import Geolocation, { GeolocationError, GeolocationResponse } from '@react-native-community/geolocation'

export function getCurrentPosition(): Promise<GeoPoint> {
  return new Promise<GeoPoint>((resolve, reject) => {
    Geolocation.getCurrentPosition(
      (position) => resolve(position.coords),
      (error: GeolocationError) => {
        reject(error)
      },
      {
        maximumAge: 1000,
      },
    )
  })
}

export function watchPosition(
  success: (position: GeoPoint) => void,
  onGeolocationError: (error: GeolocationError) => void,
): () => void {
  const watchId = Geolocation.watchPosition(
    (position: GeolocationResponse) => {
      success({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      })
    },
    (error) => {
      onGeolocationError(error)
    },
    {
      distanceFilter: 30,
    },
  )
  return () => {
    Geolocation.clearWatch(watchId)
  }
}
