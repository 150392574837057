import { Image, Linking, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { ReactElement, useEffect, useState } from 'react'
import { ID } from '@pinzme/common/src/model'
import { pinzmeWeb } from '@pinzme/components/src/registry'
import { InteractiveMap } from '@pinzme/common/src/map-model'
import { WebInteractiveMapComponent } from './web-interactive-map-component'
import {
  appStoreMarketImageUrl,
  appStorePinzMeUrl,
  googlePlayMarketImageUrl,
  googlePlayPinzMeUrl,
} from '@pinzme/components/src/common-components-utils'
import { centeredActivityIndicator } from './web-utils'

interface MapMatchParams {
  id: ID
}

interface MapMatch {
  params: MapMatchParams
}

export function WebInteractiveMapScreen({ match }: { match: MapMatch }): ReactElement {
  const [map, setMap] = useState<InteractiveMap | undefined>()

  useEffect(() => {
    void pinzmeWeb.getInteractiveMapById(match.params.id).then(setMap)
  }, [match.params.id])

  if (!map?.activeSnapshotId) {
    return centeredActivityIndicator()
  }

  return (
    <View>
      <View
        style={{ flexDirection: 'row', justifyContent: 'space-around', alignContent: 'center', alignItems: 'center' }}
      >
        <View style={{ flex: 1, alignItems: 'center' }}>
          <Text style={{ fontSize: 35, fontWeight: 'bold' }}>{map.name}</Text>
        </View>
        <View style={{ flex: 3, flexDirection: 'column', justifyContent: 'center', alignContent: 'center' }}>
          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ fontSize: 12 }}>Это и многое другое в приложении</Text>
            <Image
              source={{ uri: 'https://storage.yandexcloud.net/www.pinz.me/img/long_logo.png' }}
              style={{ marginLeft: 10, width: 55, height: 30 }}
              resizeMode={'contain'}
            />
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <TouchableOpacity
              onPress={() => {
                void Linking.openURL(appStorePinzMeUrl)
              }}
            >
              <Image
                source={{
                  uri: appStoreMarketImageUrl,
                }}
                style={styles.imageMarket}
                resizeMode={'contain'}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                void Linking.openURL(googlePlayPinzMeUrl)
              }}
            >
              <Image source={{ uri: googlePlayMarketImageUrl }} style={styles.imageMarket} resizeMode={'contain'} />
            </TouchableOpacity>
          </View>
        </View>
      </View>
      <WebInteractiveMapComponent snapshotId={map.activeSnapshotId} />
    </View>
  )
}

const styles = StyleSheet.create({
  imageMarket: {
    width: 646 / 6,
    height: 250 / 6,
  },
})
