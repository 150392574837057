import { ActivityIndicator, View } from 'react-native'
import { pinzmeBaseColor } from '@pinzme/components/src/common-components-utils'
import { ReactElement } from 'react'

export function centeredActivityIndicator(): ReactElement {
  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', height: 100 }}>
      <ActivityIndicator color={pinzmeBaseColor} />
    </View>
  )
}
