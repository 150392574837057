import { ReactElement } from 'react'
import { View, Text, Image, Dimensions, StyleSheet, TouchableOpacity, Linking } from 'react-native'
import {
  appStoreMarketImageUrl,
  appStorePinzMeUrl,
  googlePlayMarketImageUrl,
  googlePlayPinzMeUrl,
} from '@pinzme/components/src/common-components-utils'

export function MordaComponent(): ReactElement {
  return (
    <View>
      <title>Pinz.Me</title>
      <Image
        source={{ uri: 'https://storage.yandexcloud.net/pinzme/web/Pinzme_logo_GP.png' }}
        style={styles.image}
        resizeMode={'contain'}
      />
      <View style={styles.border} />
      <Text style={styles.description}>
        С помощью нашего приложения вы сможете делиться своими интересными точками с друзьями и близкими на карте
      </Text>
      <Text style={styles.bodyText}>
        Исследуйте интересные места и делитесь ими с друзьями и близкими! Это очень просто!
      </Text>
      <Text style={styles.bodyText}>
        Наше приложение - это карта, которую заполняете Вы, ваши друзья или близкие, чтобы поделиться интересными
        местами или ситуациями. Приложение включает в себя поиск и нахождение интересных мест в городе, просмотр
        каналов, добавление своих собственных меток на карту и возможность узнать о событиях вокруг.
      </Text>
      <View style={styles.border}></View>
      <TouchableOpacity
        onPress={() => {
          void Linking.openURL(appStorePinzMeUrl)
        }}
        style={styles.imageLink}
      >
        <Image
          source={{
            uri: appStoreMarketImageUrl,
          }}
          style={styles.imageMarket}
          resizeMode={'contain'}
        />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          void Linking.openURL(googlePlayPinzMeUrl)
        }}
        style={styles.imageLink}
      >
        <Image source={{ uri: googlePlayMarketImageUrl }} style={styles.imageMarket} resizeMode={'contain'} />
      </TouchableOpacity>
      {/* <Text style={styles.title}>Pinz.Me</Text>*/}
    </View>
  )
}

const styles = StyleSheet.create({
  title: {
    alignSelf: 'center',
    fontWeight: 'bold',
    fontSize: 40,
    fontFamily: 'monospace',
  },
  image: {
    alignSelf: 'center',
    width: 0.5 * Dimensions.get('window').width,
    height: 0.2 * Dimensions.get('window').height,
    padding: 1,
  },
  description: {
    alignSelf: 'center',
    backgroundColor: '#03989e',
    color: 'white',
    padding: 20,
    marginLeft: 0.02 * Dimensions.get('window').width,
    marginRight: 0.02 * Dimensions.get('window').width,
    fontFamily: 'monospace',
  },
  bodyText: {
    alignSelf: 'center',
    marginLeft: 0.05 * Dimensions.get('window').width,
    marginRight: 0.05 * Dimensions.get('window').width,
    marginTop: 0.02 * Dimensions.get('window').height,
    fontFamily: 'monospace',
    padding: 10,
  },
  imageMarket: {
    width: 0.5 * Dimensions.get('window').width,
    height: 0.1 * Dimensions.get('window').height,
    padding: 1,
  },
  imageLink: {
    alignSelf: 'center',
    margin: 1,
  },
  link: {
    fontFamily: 'monospace',
    alignSelf: 'center',
    fontStyle: 'normal',
    fontSize: 12,
  },
  border: {
    borderBottomWidth: 2,
    borderStyle: 'dotted',
    borderBottomColor: '#03989e',
    padding: 1,
    marginLeft: 0.03 * Dimensions.get('window').width,
    marginRight: 0.03 * Dimensions.get('window').width,
    marginBottom: 0.03 * Dimensions.get('window').height,
  },
})
