import { ReactElement, useState } from 'react'
import { View, StyleSheet, Text, ScrollView, Image } from 'react-native'
import { Marker } from '@pinzme/common/src/model'
import { pinzmeBaseColor } from '@pinzme/components/src/common-components-utils'

export function MarkerViewComponent({ marker }: { marker: Marker }): ReactElement {
  const [imageHeight, setImageHeight] = useState<number | undefined>()

  return (
    <View style={{ flex: 1 }}>
      <View style={{ height: 15 }} />
      <Text style={styles.titleView}>{marker.title}</Text>
      {marker.contentHTML.trim().length ? (
        <ScrollView style={{ maxHeight: 200, flexGrow: 0 }}>
          <div dangerouslySetInnerHTML={{ __html: marker.contentHTML }} />
        </ScrollView>
      ) : (
        <View style={{ height: 10 }} />
      )}
      {marker.imageUrl && (
        <View
          style={{ flex: 1 }}
          onLayout={(event) => {
            setImageHeight(event.nativeEvent.layout.height)
          }}
        >
          <Image source={{ uri: marker.imageUrl, height: imageHeight }} resizeMode={'cover'} />
        </View>
      )}
      <View style={{ height: 10 }} />
      <View style={styles.container}>
        {marker.author ? (
          <Text style={{ fontWeight: 'bold', color: pinzmeBaseColor }}>{'@' + marker.author.login}</Text>
        ) : null}
        <Text style={styles.createdAt}>{marker.createdAt.toLocaleString('ru')}</Text>
      </View>
      <View style={{ height: 10 }} />
      <View style={styles.iconGroup}>
        <View style={styles.likeIcon}>
          {marker.likesTotal ? <Text style={{ color: 'gray' }}>{marker.likesTotal}</Text> : <></>}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  titleView: {
    color: 'black',
    fontSize: 16,
    textAlign: 'center',
    fontWeight: 'bold',
    justifyContent: 'center',
  },
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  imageView: {
    height: '100%',
    borderRadius: 10,
  },
  iconGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  likeIcon: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  createdAt: {
    alignSelf: 'center',
  },
})
