import { ReactElement, useEffect, useState } from 'react'
import { Marker, MapContainer, TileLayer, Tooltip, useMap } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet/dist/images/marker-shadow.png'
import 'leaflet/dist/images/marker-icon.png'

import L from 'leaflet'
import { Dimensions, StyleSheet, View, Text } from 'react-native'
import { ChannelView, ID, MarkerMapView } from '@pinzme/common/src/model'
import { pinzmeWeb } from '@pinzme/components/src/registry'
import { MarkerPopupComponent } from './marker-popup-component'

interface MapMatchParams {
  channelId?: string
  markerId?: string
}

interface MapMatch {
  params: MapMatchParams
}

const popupRightPadding = 30
const popupWidth = Math.min(Dimensions.get('window').width - 2 * popupRightPadding, 500)

const originalIconSize = new L.Point(62, 100)
const iconScale = 2
const iconSize = new L.Point(originalIconSize.x / iconScale, originalIconSize.y / iconScale)

function LeafletMarker({
  marker,
  onClick,
  panTo,
}: {
  marker: MarkerMapView
  onClick: () => void
  panTo?: boolean
}): ReactElement {
  const iconPerson = new L.Icon({
    iconUrl: marker.mapImageUrl,
    iconAnchor: new L.Point(iconSize.x / 2, iconSize.y),
    iconSize,
  })

  const map = useMap()

  function panToMarker(): void {
    const p = map.project([marker.geoPoint.latitude, marker.geoPoint.longitude])
    p.x += popupWidth / 2
    map.panTo(map.unproject(p))
  }

  if (panTo) {
    panToMarker()
  }

  return (
    <Marker
      icon={iconPerson}
      position={[marker.geoPoint.latitude, marker.geoPoint.longitude]}
      eventHandlers={{
        click: () => {
          panToMarker()
          onClick()
        },
      }}
    >
      <Tooltip>{marker.title}</Tooltip>
    </Marker>
  )
}

export function MapScreenComponent({ match }: { match: MapMatch }): ReactElement {
  const channelId = match.params.channelId ? parseInt(match.params.channelId, 10) : undefined
  const markerId = match.params.markerId ? parseInt(match.params.markerId, 10) : undefined
  const [showMarkerId, setShowMarkerId] = useState<ID | undefined>(markerId)

  const [channel, setChannel] = useState<ChannelView | undefined>()
  const [markers, setMarkers] = useState<MarkerMapView[] | undefined>()

  async function loadMarkers(): Promise<void> {
    let realChannelId: number
    if (!channelId) {
      if (!markerId) {
        return
      }
      const marker = await pinzmeWeb.getMarkerById(markerId)
      realChannelId = marker.channelId
    } else {
      realChannelId = channelId
    }
    void pinzmeWeb.getChannelById(realChannelId).then(setChannel)
    void pinzmeWeb.getChannelMarkers(realChannelId).then(setMarkers)
  }

  useEffect(() => {
    void loadMarkers()
  }, [channelId, setChannel, setMarkers])

  return (
    <View style={{ width: '100%', height: Dimensions.get('window').height }}>
      <MapContainer center={[60, 30]} zoom={10} style={{ width: '100%', bottom: 0, top: 0, position: 'absolute' }}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {markers?.map((m: MarkerMapView) => {
          const id = m.id
          if (!id) {
            return <></>
          }
          return (
            <LeafletMarker
              marker={m}
              onClick={() => {
                setShowMarkerId(id)
                window.history.replaceState(null, m.title, `#/marker/${id}`)
              }}
              panTo={markerId === id}
            />
          )
        })}
      </MapContainer>
      {showMarkerId && (
        <View style={styles.rightView}>
          <MarkerPopupComponent markerId={showMarkerId} onClose={() => setShowMarkerId(undefined)} />
        </View>
      )}
      <View style={{ position: 'absolute', top: 25, left: 80, elevation: 2000, zIndex: 2000 }}>
        <Text style={{ fontSize: 25, fontWeight: 'bold' }}>{channel?.name}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  rightView: {
    flex: 1,
    // position: 'absolute',
    // right: 0,
    alignSelf: 'flex-end',
    flexDirection: 'row',
    // top: '50%',
    marginTop: 100,
    marginBottom: 100,
    marginRight: 30,
    backgroundColor: 'white',
    borderRadius: 30,
    paddingHorizontal: popupRightPadding,
    paddingVertical: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 2000,
    zIndex: 2000,
    width: popupWidth,
  },
})
