import { ID, Marker } from '@pinzme/common/src/model'
import { ReactElement, useEffect, useState } from 'react'
import { ActivityIndicator, TouchableOpacity, View } from 'react-native'
import { MarkerViewComponent } from './marker-view-component'
import { pinzmeWeb } from '@pinzme/components/src/registry'
import { MdClose } from 'react-icons/md'

export function MarkerPopupComponent({ markerId, onClose }: { markerId: ID; onClose: () => void }): ReactElement {
  const [marker, setMarker] = useState<Marker | undefined>()

  useEffect(() => {
    void pinzmeWeb.getMarkerById(markerId).then(setMarker)
  }, [markerId])

  return (
    <View style={{ width: '100%' }}>
      {!marker ? (
        <ActivityIndicator style={{ flex: 1, alignSelf: 'center' }} />
      ) : (
        <MarkerViewComponent marker={marker} />
      )}
      <TouchableOpacity onPress={onClose} style={{ position: 'absolute', top: 5, right: -10 }}>
        <MdClose />
      </TouchableOpacity>
    </View>
  )
}
