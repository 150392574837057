import { Point, Size } from '@pinzme/common/src/model'

export const pinzmeBaseColor = '#267578'

export const appStoreMarketImageUrl =
  'https://storage.yandexcloud.net/www.pinz.me/img/%D0%97%D0%B0%D0%B3%D1%80%D1%83%D0%B7%D0%B8%D1%82%D0%B5_App_Store.png'
export const googlePlayMarketImageUrl = 'https://storage.yandexcloud.net/www.pinz.me/img/ru_badge_web_generic.png'
export const appStorePinzMeUrl = 'https://apps.apple.com/us/app/pinz-me/id1544173088'
export const googlePlayPinzMeUrl = 'https://play.google.com/store/apps/details?id=me.pinz'

export const pointImageUrl = 'https://storage.yandexcloud.net/www.pinz.me/img/point.png'
// сюда надо записать размеры pointImageUrl
const originalPointWidth = 126
const originalPointHeight = 193
// сюда надо записать, во скоклько раз надо уменьшить в размерах на карте
const decreaseScale = 4

export function computePointPosition(
  point: Point,
  zoomScale: number,
  imageSize: Size,
  imageScreenWidth: number,
  imageScreenHeight: number,
): any {
  const scale = zoomScale * decreaseScale
  const scaledPointWidth = originalPointWidth / scale
  const scaledPointHeight = originalPointHeight / scale
  return {
    position: 'absolute',
    left: (imageScreenWidth * point.x) / imageSize.width - scaledPointWidth / 2,
    top: (imageScreenHeight * point.y) / imageSize.height - scaledPointHeight / 2,
    width: scaledPointWidth,
    height: scaledPointHeight,
    zIndex: 1,
  }
}

export function computeImageScreenHeight(imageScreenWidth: number, imageSize: Size): number {
  return (imageScreenWidth * imageSize.height) / imageSize.width
}
