import { kebabize } from './common-utils'
import { parseBoolean, parseString } from './parsing'

export interface AppInfo {
  applicationName: string
  brand: string
  buildNumber: string
  bundleId: string
  deviceId: string
  deviceType: string
  systemVersion: string
  systemName: string
  uniqueId: string
  version: string
  hasNotch: boolean
  isLandscape: boolean
  isTablet: boolean
}

export function serializeToHeaders(appInfo: AppInfo): { [name: string]: string } {
  const result: { [name: string]: string } = {}
  for (const [name, value] of Object.entries(appInfo)) {
    result[toHeaderName(name)] = value
  }
  return result
}

export function deserializeFromHeaders(headers: { [key: string]: string | string[] | undefined }): AppInfo {
  return {
    applicationName: parseString(headers, toHeaderName('applicationName')),
    brand: parseString(headers, toHeaderName('brand')),
    buildNumber: parseString(headers, toHeaderName('buildNumber')),
    bundleId: parseString(headers, toHeaderName('bundleId')),
    deviceId: parseString(headers, toHeaderName('deviceId')),
    deviceType: parseString(headers, toHeaderName('deviceType')),
    systemVersion: parseString(headers, toHeaderName('systemVersion')),
    systemName: parseString(headers, toHeaderName('systemName')),
    uniqueId: parseString(headers, toHeaderName('uniqueId')),
    version: parseString(headers, toHeaderName('version')),
    hasNotch: parseBoolean(headers, toHeaderName('hasNotch')),
    isLandscape: parseBoolean(headers, toHeaderName('isLandscape')),
    isTablet: parseBoolean(headers, toHeaderName('isTablet')),
  }
}

function toHeaderName(key: string): string {
  return `x-${kebabize(key)}`
}
